import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ServicesSection() {
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              title="Provided Featrues"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {/* Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="E2E Application Development" icon="fas fa-bell" iconColor="#8a55df" text="Blockchain development<br class='d-none d-xs-block d-lg-none d-xl-block'> Web Development<br class='d-none d-xs-block  d-lg-none d-xl-block'> SAAS
            <br class='d-none d-xs-block  d-lg-none d-xl-block'> Micro Services
            <br class='d-none d-xs-block  d-lg-none d-xl-block'> Mobile Development 
            <br class='d-none d-xs-block  d-lg-none d-xl-block'> Serverless Applications Development" />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Quality Assurance Engineering" icon="fas fa-layer-group" iconColor="#8a55df" text="Test Automation
            <br class='d-none d-xs-block d-lg-none d-xl-block'> E2E Testing
            <br class='d-none d-xs-block d-lg-none d-xl-block'>  Manual Testing" />
          </Col>
          {/*/ .Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Application Modernization" icon="fas fa-envelope" iconColor="#8a55df" text="Cloud Migration
            <br class='d-none d-xs-block d-lg-none d-xl-block'> Serverless Migration" />
          </Col>
          {/*/ .Single Services */}
          {/*/ .Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Software Integration" icon="fas fa-chart-pie" iconColor="#8a55df" text="Google Services
            <br class='d-none d-xs-block d-lg-none d-xl-block'> CRM Intergration
            <br class='d-none d-xs-block  d-lg-none d-xl-block'>  Chat bot integration" />
          </Col>
          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  )
}

