import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';


import image from '../../../../assets/image/services/product-engineering/7.png'


export default function ContentSectionOne() {

  return (
    <Content>
      {/* <Content.SectionShape>
        <Img src="../../../assets/image/project-management/l2-content-1-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
      </Content.SectionShape> */}
      <Container>
        <Content.Inner>
          <Row className="align-items-center justify-content-center justify-content-lg-start">
            <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
              <Content.TextBlock>
                <Content.Title as="h2"> <SuperTag value="Product <br className='d-none d-lg-block' />
                
                 "

                />
                  <div style={{ color: "#8a55df" }} >
                    <ReactTypingEffect
                      text={["Engineering", "Engineering"]}
                      className="highlight-text d-lg-block "
                      speed="150"
                      eraseSpeed="100"
                      typingDelay="400"
                      eraseDelay="800"
                      cursorClassName="typed-cursor">

                    </ReactTypingEffect>
                  </div>




                </Content.Title>

                <Content.Text> <SuperTag value="Axcer team is having a great capacity on E2E application development including Quality Assurance
                 Engineering in order to deliver a bug free solution to you. Our experience with cutting edge technologies will be a definite advantage 
                 in modernization your legacy systems. We truly understand that your current business process is integrated with many other systems and
                  we will help you to integrate all of them in to one common place" /> </Content.Text>
                {/* <Content.Button to="/contact"> Contact Us
                </Content.Button> */}
                <Content.ButtonTwo to={'/contact'}>Get Quotation</Content.ButtonTwo>

              </Content.TextBlock>
            </Col>
            <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
              <Content.Image>
                {/* <img className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" /> */}

                <Img className="w-100" src='../../../../assets/image/services/product-engineering/7.png' alt="content" layout="fullWidth" placeholder="blurred" />

              </Content.Image>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}