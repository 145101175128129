import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Integration from "./style"
import { Images } from "~data"
import SectionTitle from './Components/SectionTitle'
import image from "../../../../assets/image/services/enterprise-applications/1.gif"


const IntegrationSection = ({ ...rest }) => {
  return (
    <Integration
      backgroundColor="#f2f5fb"
    >
      <Container>
        <Row className="row justify-content-center">
          <Col className="col-xl-10">
            <Integration.Box className="text-center">
              <SectionTitle
                title="Technologies"
              // text="Create custom landing pages with Fastland that converts <br class='d-none d-sm-block'> more visitors than any website."
              />
              {/* <Integration.Button to="/">Explore All Apps</Integration.Button> */}
            </Integration.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <img src={image} ></img>

        </Row>

      </Container>
    </Integration>
  )
}

export default IntegrationSection
