import React from "react";
import { PageWrapper } from "~components/Core";


import FooterFive from '~sections/agency/FooterFive'

import ContentOne from '~sections/services/product-engineering/Content'

import FeatureSection from '~sections/services/product-engineering/Feature'
import ServicesSectionTwo from '~sections/services/product-engineering/ServicesTwo'

import ContactSection from "~sections/contact/ContactOne/ContactSection";

import CtaSection from '~sections/it/Cta'

import Services from '~sections/services/product-engineering/Services'

import Integration from '~sections/services/enterprise-applications/Integration'


export default function Project() {
    return (
        <PageWrapper innerPage={true}>

            <ContentOne />
            <Services />
            {/* <FeatureSection />
            <ServicesSectionTwo /> */}
            {/* <ContactSection /> */}

            <Integration />

            <CtaSection />

            <FooterFive />

        </PageWrapper>
    )
}
